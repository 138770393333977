:root {
	--background-color: rgba(238, 238, 238, 1.0);

	--peer-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
	--peer-border: 1px solid rgba(255, 255, 255, 0.15);
	--peer-empty-avatar: url('./images/buddy.svg');
	--peer-bg-color: rgba(255, 255, 255, 0.5);
	--peer-video-bg-color: rgba(0, 0, 0, 0.75);

	--active-speaker-border-color: rgba(255, 255, 255, 1.0);
	--selected-peer-border-color: rgba(55, 126, 255, 1.0);
}

html
{
	height: 100%;
	width: 100%;
	font-family: 'Roboto';
	font-weight: 300;
	margin : 0;
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body
{
	height: 100%;
	width: 100%;
	font-size: 16px;
	margin: 0;
}

#multiparty-meeting
{
	height: 100%;
	width: 100%;
}

/* PCSS */

/*
#f26521 - OPEN UP2U orange dark
#f39200 - OPEN UP2U orange light
*/


.MuiButton-containedSecondary, .MuiFab-secondary, .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #f26521 !important;
}

.MuiIconButton-colorSecondary, .MuiCheckbox-colorSecondary.Mui-checked, .MuiSwitch-colorSecondary.Mui-checked, .MuiSvgIcon-colorSecondary {
	color: #f26521 !important;
}

button.MuiButton-containedSecondary {
	padding: 6px 8px !important;
}

.loginButton {
	padding: 6px 18px !important;
}

.loginButton img, .loginButton .MuiAvatar-circle {
	width: 25px;
	height: 25px;
}

.loginButtonText {
	margin-right: 12px;
	margin-left: 6px;
}

.cookieConsent {
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.cookieConsent a:visited {
	color: #ea5b0c;
}

.cookieConsent a {
  color: #f39200;
}